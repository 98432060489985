<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-13 17:33:55
 * @ Description: Fixer's contact us page in landing section of the app.
 -->

<template>
    <div>
        <!-- Desktop -->
        <v-row
            v-if="$vuetify.breakpoint.mdAndUp"
            style="padding-top: 90px; min-height: calc(100vh - 110px);"
            align="center"
        >
            <v-col
                cols="12"
            >
                <v-form
                    ref="contactUsForm"
                    v-model="validContactUsForm"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            class="text-center pb-6 mont-50-eb"
                            cols="12"
                            style="color: #2E567A;"
                        >
                            Contact Us
                        </v-col>
                        <!-- Heading -->

                        <!-- Image -->
                        <v-col
                            cols="6"
                            class="pl-0"
                            style="max-height: calc(100vh - 215px);"
                            align-self="center"
                        >
                            <v-img
                                src="/landing/contactus/LandingContactUsBackground.webp"
                                max-height="100%"
                                class="my-auto"
                            />
                        </v-col>
                        <!-- Image -->

                        <!-- Contact Form -->
                        <v-col
                            cols="6"
                            style="display: flex; justify-content: center;"
                        >
                            <v-row
                                :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 555px;' : 'max-width: 460px;'"
                            >
                                <v-col
                                    cols="12"
                                    class="text-center os-29-sb"
                                >
                                    Have a question?
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center os-14-r"
                                >
                                    You can send us an e-mail via our form or <br />
                                    use our live chat found in the bottom right corner.
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="py-0 os-15-sb"
                                >
                                    First Name<span style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="user.first_name"
                                        :rules="nameRules"
                                        counter="50"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="py-0 os-15-sb"
                                >
                                    Last Name<span style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="user.last_name"
                                        :rules="nameRules"
                                        counter="50"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0 os-15-sb"
                                >
                                    Email<span style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="user.email"
                                        :rules="emailRules"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0 os-15-sb"
                                >
                                    Message<span style="color: red;">*</span>
                                    <br />
                                    <v-textarea
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r message"
                                        background-color="white"
                                        rows="3"
                                        v-model="user.message"
                                        :rules="messageRules"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center pt-0 os-13-sb"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        color="#FFA858"
                                        @click="send"
                                        :disabled="!validContactUsForm"
                                    >
                                        Get In Touch
                                    </v-btn>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-14-r"
                                >
                                    <span class="os-14-sb">Alternatively Contact Us At:</span><br />
                                    support@9to5fixer.com <br />
                                    +27 12 944 2020
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Contact Form -->
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-row
            v-else-if="$vuetify.breakpoint.smAndDown"
            style="padding-top: 90px; min-height: 100vh;"
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
            >
                <v-form
                    ref="contactUsForm"
                    v-model="validContactUsForm"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            class="text-center pb-6 mont-40-eb"
                            cols="12"
                            style="color: #2E567A;"
                        >
                            Contact Us
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center os-29-sb"
                        >
                            Have a question?
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center os-14-r"
                        >
                            You can send us an e-mail via our form or <br v-if="!$store.state.general.customBreakpoints.xxs "/>
                            use our live chat found in the bottom right corner.
                        </v-col>
                        <!-- Heading -->

                        <!-- Contact Form -->
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                        >
                            First Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                v-model="user.first_name"
                                :rules="nameRules"
                                counter="50"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                        >
                            Last Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                v-model="user.last_name"
                                :rules="nameRules"
                                counter="50"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                        >
                            Email<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                v-model="user.email"
                                :rules="emailRules"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                        >
                            Message<span style="color: red;">*</span>
                            <br />
                            <v-textarea
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r message"
                                background-color="white"
                                rows="3"
                                v-model="user.message"
                                :rules="messageRules"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center pt-0 os-13-sb"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="send"
                                :disabled="!validContactUsForm"
                            >
                                Get In Touch
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-14-r"
                        >
                            <span class="os-14-sb">Alternatively Contact Us At:</span><br />
                            support@9to5fixer.com <br />
                            +27 12 944 2020
                        </v-col>
                        <!-- Contact Form -->
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <!-- Mobile -->
    </div>
</template>
<script>
    import { ContactUsController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ContactUsPage',

        computed: {
            nameRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            emailRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => /\S+@\S+\.\S+/.test(v) || 'Invalid e-mail address'
                ]
            },

            messageRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field'
                ]
            }
        },

        data () {
            return {
                validContactUsForm: false,
                user: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    message: ''
                },
                contactUsController: null
            }
        },

        beforeMount () {
            this.contactUsController = new ContactUsController()
        },

        methods: {
            async send () {
                await this.contactUsController.createEmail(this.user)

                this.$store.commit('setSnackbar', {
                    text: 'Message sent',
                    show: true,
                    color: '#069B34'
                })

                this.user = {
                    first_name: '',
                    last_name: '',
                    email: '',
                    message: ''
                }

                this.$refs.contactUsForm.resetValidation()
            }
        }
    }
</script>
<style scoped>
    >>> .message div.v-input__slot.white {
        min-height: 0 !important;
        height: 72px !important;
    }
</style>
